/*Hovering on 'UserTypes.js'*/
.typeDescription {
  background-color: #f7f7ff;
  border: 1px solid #3c0096;
  color: #3c0096;
  text-decoration: none;
  border-radius: 20px;
  cursor: pointer;
}

.typeDescription:hover {
  filter: brightness(0.9);
}

/*PRIVATE STYLES*/
.navbarIcon {
  padding: 0.5vh;
}

.navbarIcon:hover {
  background-color: rgba(60, 0, 150, 0.25);
  cursor: pointer;
  border-radius: 50px;
}

/* DIAS DE FUNCIONAMENTO STYLE */
#dayselector {
  border: 1px solid rgb(224, 224, 224);
  height: 40px;
  border-radius: 25px;
}

#dayselector div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(224, 224, 224, 0.5);
  width: 30px;
  height: 30px;
  border-radius: 50px;
}

#dayselector div:hover {
  background-color: rgb(192, 192, 192, 0.5);
  cursor: pointer;
}

#dayselector div:active {
  font-weight: bold;
}

#dayselector .selectedDay {
  background-color: rgba(60, 0, 150, 1);
  color: #fff;
  font-weight: bold;
}

#dayselector .selectedDay:hover {
  background-color: rgba(60, 0, 150, 0.75);
}

/* NOTIFICATION PAGE */

.notificationItem:hover {
  filter: brightness(0.9);
  background-color: #e4e4e4;
  cursor: pointer;
}

/* ALL TABLES NEED TO HAVE THE SAME PATTERN */
.thead-blueen {
  background-color: #3C0096;
  color: #f7f7ff;
}

.tbody-blueen {
  background-color: #f7f7ff;
  color: #170F11;
}

.MuiModal-root p::selection, .MuiModal-root h4::selection{
  background-color: #3C0096 !important;
  color: #f7f7ff !important;
}